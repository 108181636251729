<template>
    <van-dropdown-menu>
        <van-dropdown-item :title="title" ref="language">
            <van-cell v-for="lau in languageOption" :key="lau.value" @click="setLanguage(lau)">
                <template>
                    <div style="display:flex;align-items: center;">
                        <van-image v-if="lau.image" width="20px" height="20px" fit="cover" :src="lau.image" />
                        <div :style="{ 'margin-left': '10px', 'color': language == lau.value ? '#ee0a24' : '#323233' }">
                            {{ lau.text }}
                        </div>
                    </div>
                </template>
            </van-cell>
        </van-dropdown-item>
    </van-dropdown-menu>
</template>
  
<script>
const Config = {
    'DEFAULT': {
        option: [
            { text: 'English', value: 'en' }
        ]
    },
    'MY': {
        option: [
            { text: 'English', value: 'en' },
            { text: 'Bahasa Malaysia', value: 'my' }
        ]
    },
    'TH': {
        option: [
            { text: 'English', value: 'en', image: require('/public/img/th-en.png') },
            { text: 'ภาษาไทย', value: 'th', image: require('/public/img/th-th.png') }
        ]
    },
    'PH': {
        option: [
            { text: 'English', value: 'EN' },
            { text: '中文', value: 'ZH' },
        ]
    },
    'VN': {
        option: [
            { text: 'Vietnamese', value: 'vn' },
            { text: 'English', value: 'en' },
        ]
    }
}

import {
    DropdownMenu,
    DropdownItem,
    Image as VanImage,
} from 'vant';

export default {
    components: {
        [VanImage.name]: VanImage,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    data() {
        return {
            language: '',
            country: '',
            title: '',
            languageOption: [],
        };
    },
    created() {
        this.init()
    },
    watch: {
        '$route.params.lang': function () {
            this.init()
        },
    },
    methods: {
        init() {
            this.language = this.$route.params.lang
            this.country = this.$route.params.countryPath
            this.languageOption = Config[this.country] ? Config[this.country].option : Config['DEFAULT'].option
            this.$nextTick(() => {
                this.setLanguage(this.languageOption.find(i => i.value == this.language) || this.languageOption[0], true)
            });
        },
        setLanguage(language, not_toggle) {
            this.language = language.value
            this.title = language.text
            this.$root.$i18n.locale = language.value
            if (!not_toggle) {
                this.$refs.language.toggle()
            }
        },
    }
};
</script>
  
<style lang="less"></style>
  